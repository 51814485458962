import { jstNow, convertToJst } from '@kkhs/hakari-utils';
import { subDays, isBefore, parseISO } from 'date-fns';
import { dateToAWSDate, brandAWSDate } from '@/shared/utils';

/** fromDateがoperationStartDateよりも過去の場合、fromDateにoperationStartDateを渡す */
export function convertAilerningPeriod(operationStartDate: string | undefined) {
  const today = jstNow();
  const fromDate = dateToAWSDate(subDays(today, 30));
  const toDate = dateToAWSDate(today);
  if (!operationStartDate) {
    return { fromDate, toDate };
  }
  if (isBefore(convertToJst(parseISO(fromDate)), convertToJst(parseISO(operationStartDate)))) {
    return { fromDate: brandAWSDate(operationStartDate), toDate };
  }
  return { fromDate, toDate };
}
