import { useQuery } from '@apollo/client';
import { jstNow } from '@kkhs/hakari-utils';
import { addDays } from 'date-fns';
import { useMemo } from 'react';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';
import { useNotificationsQuery } from '@/gql/apollo';
import { queryDoc } from '@/pages/トップページ/TopContainer/NotificationSection/NotificationList/hooks/useNotifications/graphql.stockSale';
import { dateToAWSDate } from '@/shared/utils';
import { convertAilerningPeriod } from './convertAiLearningPeriod';
import { AI_LEARNING_MAX_COUNT } from '../../../constants';
import { NotificationBlock } from '../../../types';

/**
 * お知らせに表示する項目を取得する
 * - 在庫を計上する画面に存在する医薬品の合計件数（納品日日付関係なし）
 * - 発注予約画面に存在する医薬品の合計件数（発注日関係なし）
 * - 発注予約画面に存在する医薬品のうち、現在日＋１日後の発注予約件数
 * - AIに学習させる画面に存在する医薬品のうち、現在日から過去30日間の入力必須の件数
 * - AIに学習させる画面に存在する医薬品のうち、現在日から過去30日間の要確認の件数
 * - 法人内融通のうち対応が必要なアクションの件数（他店舗の小分け依頼、他店舗の引き取り依頼、引き取り先選択、他店舗からの未入庫、他店舗への未出庫）
 * - ファルマーケットへの出庫待ちの件数
 * - ファルマーケットへの売却査定承認待ちの件数
 */
export function useNotificationBlocks() {
  const today = jstNow();
  const operationStartDate = currentPharmacySelectors.useValue()?.operationStartDate ?? undefined;
  const aiLearningPeriod = useMemo(
    () => convertAilerningPeriod(operationStartDate),
    [operationStartDate],
  );
  const { data, loading: isLoading } = useNotificationsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      today: dateToAWSDate(today),
      toDate: dateToAWSDate(addDays(today, 1)),
      aiLearningFromDate: aiLearningPeriod.fromDate,
      aiLearningToDate: aiLearningPeriod.toDate,
      aiLearningCount: AI_LEARNING_MAX_COUNT,
    },
  });
  const { data: stockSaleData } = useQuery(queryDoc, {
    context: {
      api: 'stockSale',
    },
    fetchPolicy: 'cache-and-network',
  });

  const notificationBlocks = useMemo(() => {
    if (!data && !stockSaleData) return [];
    const blocks: NotificationBlock[] = [];
    const {
      medicineReceivings,
      orderPlans,
      orderPlanDailyCounts,
      inputRequiredCount,
      checkNeededCount,
      ownGiveCount,
      othersGiveCount,
      othersTakeCount,
      unstoredCount,
      unshippedCount,
    } = data ?? {
      medicineReceivings: { edges: [] },
      orderPlans: { edges: [] },
      orderPlanDailyCounts: { items: [] },
      inputRequiredCount: { edges: [] },
      checkNeededCount: { edges: [] },
      ownGiveCount: 0,
      othersGiveCount: 0,
      othersTakeCount: 0,
      unstoredCount: 0,
      unshippedCount: 0,
    };
    const { beforeShippingCount, beConfirmedCount } =
      stockSaleData?.stockSaleRequestListItems?.reduce(
        (acc, item) => {
          switch (item.__typename) {
            case 'StockSaleRequestListItemBeforeShipping': {
              return { ...acc, beforeShippingCount: acc.beforeShippingCount + 1 };
            }
            case 'StockSaleRequestListItemToBeConfirmed': {
              return { ...acc, beConfirmedCount: acc.beConfirmedCount + 1 };
            }
            default: {
              return acc;
            }
          }
        },
        { beforeShippingCount: 0, beConfirmedCount: 0 },
      ) ?? { beforeShippingCount: 0, beConfirmedCount: 0 };

    // 在庫計上待ち、入出庫処理待ち、ファルマーケットへの出庫待ちの医薬品の件数
    if (
      medicineReceivings.edges.length !== 0 ||
      unstoredCount !== 0 ||
      unshippedCount !== 0 ||
      beforeShippingCount !== 0
    ) {
      blocks.push({
        key: 'receiving-over100',
        type: 'todo',
        itemType: 'receiving',
        count: medicineReceivings.edges.length,
        unstoredCount,
        unshippedCount,
        beforeShippingCount,
      });
    }
    // 融通のうち対応が必要なアクションの件数（他店舗の小分け依頼、他店舗の引き取り依頼、引き取り先選択、売却承認査定待ち）
    if (
      ownGiveCount !== 0 ||
      othersGiveCount !== 0 ||
      othersTakeCount !== 0 ||
      beConfirmedCount !== 0
    ) {
      blocks.push({
        key: 'transfer-request',
        type: 'todo',
        itemType: 'transferRequest',
        ownGiveCount,
        othersGiveCount,
        othersTakeCount,
        beConfirmedCount,
      });
    }

    // operationStartDateがあり、入力必須、要確認のどちらかにデータがある場合、データの件数
    if (
      !!operationStartDate &&
      (inputRequiredCount?.edges.length !== 0 || checkNeededCount?.edges.length !== 0)
    ) {
      blocks.push({
        key: 'aiLearning-over100',
        type: 'todo',
        itemType: 'aiLearning',
        inputRequiredCount: inputRequiredCount?.edges
          .map(({ node }) => node.prescriptionDetails.length)
          .reduce((acc, cur) => acc + cur, 0),
        checkNeededCount: checkNeededCount?.edges
          .map(({ node }) => node.prescriptionDetails.length)
          .reduce((acc, cur) => acc + cur, 0),
        ...aiLearningPeriod,
      });
    }

    // 発注予約中の医薬品情報
    if (orderPlans.edges.length !== 0) {
      // 今日明日の発注予約中の医薬品情報
      const tomorrowOrderPlanCount = orderPlanDailyCounts.items.reduce(
        (acc, cur) => acc + cur.count,
        0,
      );
      blocks.push({
        key: 'orderPlan-over100',
        type: 'info',
        itemType: 'orderPlan',
        totalCount: orderPlans.edges.length,
        tomorrorCount: tomorrowOrderPlanCount,
      });
    }

    return blocks;
  }, [aiLearningPeriod, data, operationStartDate, stockSaleData]);
  return { notificationBlocks, isLoading };
}
