import { useQuery } from '@apollo/client';
import { Box, Button, Flex, Skeleton, Stack, Text } from '@chakra-ui/react';
import { ButtonLink, NumberText } from '@kkhs/hakari-ui';
import { useCallback } from 'react';
import { repositoryContainerGetter } from '@/di/repository';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';
import { queryDoc } from './graphql.stockSale';

export function PharmarketPromotionForMedicinePriceChangeCampaignInfobar() {
  const userMonitoringRepository = repositoryContainerGetter.userMonitoring();
  const { data, loading } = useQuery(queryDoc, {
    context: {
      api: 'stockSale',
    },
  });
  const 不動在庫総額 = data?.pharmarketPromotionBannerInfo.immovablyTotalMedicinePriceAmount;
  const 概算売却金額 = data?.pharmarketPromotionBannerInfo.immovablyTotalEstimatePriceAmount;
  const currentPharmacy = currentPharmacySelectors.useValue();
  const pharmacyId = currentPharmacy?.id;
  const musubiCode = currentPharmacy?.musubiCode;

  const handleClickButton = useCallback(() => {
    userMonitoringRepository.sendEvent({
      key: 'トップ画面_ファルマ促進バナー_CLICKED',
      contexts: { pharmacyId, musubiCode },
    });
  }, [userMonitoringRepository, pharmacyId, musubiCode]);

  return (
    <Stack backgroundColor="primary.600" p={1} gap={1}>
      <Text color="white" fontSize="md" align="center" fontWeight="bold">
        AI在庫からの医薬品売却について
      </Text>
      <Stack backgroundColor="primary.50" px={3} pt={2} pb={3} gap={0}>
        <Text fontSize="md" align="center" fontWeight="bold">
          現行薬価での売却は
        </Text>
        <Text color="red.500" fontSize="2xl" lineHeight={1} align="center" fontWeight="bold" mt={1}>
          3/19
          <Text as="span" color="red.500" fontWeight="bold" mx={0.5}>
            (火)
          </Text>
          必着分
          <Text as="span" color="red.500" fontWeight="bold">
            まで
          </Text>
          <Text as="span" color="slate.700" fontSize="sm" fontWeight="normal">
            ※1
          </Text>
        </Text>
        <Text fontSize="md" align="center" fontWeight="bold" mt={4}>
          お早めに
          <Text as="span" color="red.500" fontWeight="bold">
            不動在庫
          </Text>
          をご確認ください
        </Text>
        <Box bg="primary.100" mt={1} pt={2} pb={4} px={4} borderRadius="lg" gap={2}>
          <Stack gap={2}>
            <Stack gap={0}>
              <Text fontWeight="bold">不動期間90日以上の在庫総額</Text>
              <Flex justifyContent="flex-end" alignItems="baseline" gap={1}>
                <Skeleton isLoaded={!loading} borderRadius="md" w="100%">
                  <NumberText as="span" fontWeight="bold" size="xl" lineHeight={1} textAlign="end">
                    {不動在庫総額}
                  </NumberText>
                </Skeleton>
                <Text fontWeight="bold" color="slate.700">
                  円
                </Text>
              </Flex>
            </Stack>
            <Stack gap={0}>
              <Text fontWeight="bold">
                概算売却金額
                <Text as="span" fontSize="sm" fontWeight="normal">
                  ※2
                </Text>
              </Text>
              <Flex justifyContent="flex-end" alignItems="baseline" gap={1}>
                <Skeleton isLoaded={!loading} borderRadius="md" w="100%">
                  <NumberText as="span" fontWeight="bold" size="xl" lineHeight={1} textAlign="end">
                    {概算売却金額}
                  </NumberText>
                </Skeleton>
                <Text fontWeight="bold" color="slate.700">
                  円
                </Text>
              </Flex>
            </Stack>
          </Stack>
        </Box>
        <ButtonLink
          href="/stock?via=pharmarketBanner&immovablyDays=overNinetyDays&withStopped=true"
          onClick={handleClickButton}
        >
          <Button mt={2} w="100%">
            不動期間90日以上の在庫を確認
          </Button>
        </ButtonLink>
        <Stack gap={1} mt={2}>
          <Text fontSize="xs" lineHeight={1.2}>
            ※1 ファルマーケット倉庫への到着が必要です
          </Text>
          <Text fontSize="xs" lineHeight={1.2}>
            ※2 最終の売却額は、本査定後に確定します
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
}
