import { useQuery } from '@apollo/client';
import { Button, Flex, Skeleton, Stack, Text } from '@chakra-ui/react';
import { AddCartIcon, ButtonLink } from '@kkhs/hakari-ui';
import { isNullOrUndefined } from '@kkhs/hakari-utils';
import { useCallback } from 'react';
import { repositoryContainerGetter } from '@/di/repository';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';
import { queryDoc } from './graphql.stockSale';

type PharmarketPromotionAmountProps = {
  title: string;
  hasReferenceMark?: boolean;
  children: number | undefined | null;
  loading: boolean;
};
function PharmarketPromotionAmount({
  title,
  children: amount,
  hasReferenceMark = false,
  loading,
}: PharmarketPromotionAmountProps) {
  const convertAmountToString = (amountNum: number | undefined | null): string => {
    if (isNullOrUndefined(amountNum) || Number.isNaN(amountNum)) {
      return '-';
    }
    return amountNum.toLocaleString();
  };

  return (
    <Stack>
      <Text color="primary.600" fontSize="md" fontWeight="bold">
        {title}
        {hasReferenceMark && (
          <Text as="span" color="primary.600" fontSize="sm" fontWeight="bold">
            ※
          </Text>
        )}
      </Text>
      <Flex justifyContent="flex-end" alignItems="baseline" gap={1}>
        <Skeleton isLoaded={!loading} borderRadius="md" h="28px" w="100%">
          <Text
            fontWeight="bold"
            fontSize="28px"
            lineHeight={1}
            textAlign="end"
            color="primary.800"
          >
            {convertAmountToString(amount)}
          </Text>
        </Skeleton>
        <Text fontWeight="bold" color="primary.800">
          円
        </Text>
      </Flex>
    </Stack>
  );
}

/**
 * デフォルトで出るファルマ促進バナー
 */
export function PharmarketPromotionStandardInfobar() {
  const userMonitoringRepository = repositoryContainerGetter.userMonitoring();
  const { data, loading } = useQuery(queryDoc, {
    context: {
      api: 'stockSale',
    },
  });
  const 不動在庫総額 = data?.pharmarketPromotionBannerInfo.immovablyTotalMedicinePriceAmount;
  const 概算売却金額 = data?.pharmarketPromotionBannerInfo.immovablyTotalEstimatePriceAmount;
  const currentPharmacy = currentPharmacySelectors.useValue();
  const pharmacyId = currentPharmacy?.id;
  const musubiCode = currentPharmacy?.musubiCode;

  const handleClickButton = useCallback(() => {
    userMonitoringRepository.sendEvent({
      key: 'トップ画面_ファルマ促進バナー_CLICKED',
      contexts: { pharmacyId, musubiCode },
    });
  }, [userMonitoringRepository, pharmacyId, musubiCode]);

  return (
    <Stack backgroundColor="primary.600" borderWidth={4} borderColor="primary.600" spacing={1}>
      <Stack gap={0} alignItems="center" p={1.5}>
        <Text color="primary.50" fontSize="sm" fontWeight="bold" lineHeight="14px">
          ファルマーケットに
        </Text>
        <Text color="primary.50" fontSize="md" fontWeight="bold">
          <Text as="span" color="primary.50" fontSize="lg" fontWeight="bold" lineHeight="26px">
            不動在庫
          </Text>
          を
          <Text as="span" color="primary.50" fontSize="lg" fontWeight="bold" lineHeight="26px">
            売却
          </Text>
        </Text>
      </Stack>
      <Stack backgroundColor="primary.50" p={4}>
        <Stack backgroundColor="primary.100" borderRadius="10px" p={3}>
          <PharmarketPromotionAmount title="不動期間90日以上の在庫総額" loading={loading}>
            {不動在庫総額}
          </PharmarketPromotionAmount>
          <PharmarketPromotionAmount title="概算売却金額" hasReferenceMark loading={loading}>
            {概算売却金額}
          </PharmarketPromotionAmount>
        </Stack>
        <Stack spacing={1}>
          <ButtonLink
            href="/stock?via=pharmarketBanner&immovablyDays=overNinetyDays&withStopped=true"
            onClick={handleClickButton}
          >
            <Button w="100%" leftIcon={<AddCartIcon w={33} h={33} />} h="60px">
              <Stack alignItems="start" spacing={0}>
                <Text color="white" fontSize="sm" fontWeight="bold" lineHeight={2}>
                  不動期間90日以上の
                </Text>
                <Text color="white" fontSize="lg" fontWeight="bold" lineHeight="24px">
                  在庫を確認する
                </Text>
              </Stack>
            </Button>
          </ButtonLink>
          <Text fontSize="xs" lineHeight={1.2}>
            ※最終の売却額は、検品後に確定します
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
}
