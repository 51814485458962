import { AWSDate, differenceInCalendarDaysOnAWSDate } from '@/shared/utils';

/**
 *  利用開始日よりも前かどうか
 */
export function isBeforeOperationStartDate({
  operationStartDate,
  targetDate,
}: {
  operationStartDate: AWSDate;
  targetDate: AWSDate;
}) {
  return differenceInCalendarDaysOnAWSDate(operationStartDate, targetDate) > 0;
}
