import { graphql } from '@/stockSale/gql';

// ファルマーケットへの出庫待ちの件数を表示する

export const queryDoc = graphql(`
  query NumberOfToBeConfirmedItems {
    stockSaleRequestListItems {
      ... on StockSaleRequestListItemBeforeShipping {
        id
      }
      ... on StockSaleRequestListItemConfirmed {
        id
      }
      ... on StockSaleRequestListItemToBeConfirmed {
        id
      }
      ... on StockSaleRequestListItemUnderInspection {
        id
      }
    }
  }
`);
