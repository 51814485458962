import { Button, ComponentWithAs, IconProps, Text } from '@chakra-ui/react';
import { ButtonLink } from '@kkhs/hakari-ui';

export function BigButton({
  icon: Icon,
  label,
  href,
  onClick,
}: {
  icon: ComponentWithAs<'svg', IconProps>;
  label: string;
  href: string;
  onClick?: () => void;
}) {
  return (
    <ButtonLink href={href}>
      <Button h={24} w="100%" leftIcon={<Icon h="60px" w="60px" mx={2} />} onClick={onClick}>
        <Text fontSize="lg" mr="auto" color="white">
          {label}
        </Text>
      </Button>
    </ButtonLink>
  );
}
