import { Box, Image, Link } from '@chakra-ui/react';
import { useCallback } from 'react';
import { repositoryContainerGetter } from '@/di/repository';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';

type Props = {
  href: string;
  src: string;
  alt: string;
};

export function PharmarketPromotionImageOnlyInfobar({ href, src, alt }: Props) {
  const userMonitoringRepository = repositoryContainerGetter.userMonitoring();
  const currentPharmacy = currentPharmacySelectors.useValue();
  const pharmacyId = currentPharmacy?.id;
  const musubiCode = currentPharmacy?.musubiCode;

  const handleClickButton = useCallback(() => {
    userMonitoringRepository.sendEvent({
      key: 'トップ画面_ファルマ促進バナー_CLICKED',
      contexts: { pharmacyId, musubiCode },
    });
  }, [userMonitoringRepository, pharmacyId, musubiCode]);

  return (
    <Box>
      <Link onClick={handleClickButton} href={href} isExternal>
        <Image src={src} alt={alt} _hover={{ cursor: 'pointer', opacity: 0.8 }} />
      </Link>
    </Box>
  );
}
