import { Divider, Flex, Grid, GridItem, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import {
  Link,
  FeatureIcon,
  OrderIcon,
  PatientInfoIcon,
  StockIcon,
  ReceivingIcon,
  StocktakeIcon,
  SettingsIcon,
  Typography,
} from '@kkhs/hakari-ui';
import { useMemo } from 'react';
import { repositoryContainerGetter } from '@/di/repository';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';
import { useReceivingStartDate } from '@/entities/receiving/hooks/useReceivingStartDate';
import { IFeatureFlagRepository } from '@/repository/featureFlag/interface';
import { BigButton } from './BigButton';
import { NotificationSection } from './NotificationSection';
import {
  PharmarketPromotionForMedicinePriceChangeCampaignInfobar,
  PharmarketPromotionImageOnlyInfobar,
  PharmarketPromotionStandardInfobar,
} from './PharmarketPromotion';

const renderPharmarketPromotionInfobar = (
  infobarOption: IFeatureFlagRepository.PharmarketPromotionInfobarOption,
) => {
  switch (infobarOption) {
    case 'standard':
      return <PharmarketPromotionStandardInfobar />;
    case 'medicine_price_change_campaign':
      return <PharmarketPromotionForMedicinePriceChangeCampaignInfobar />;
    case 'image_only':
      return (
        <PharmarketPromotionImageOnlyInfobar
          href="https://www.pharmarket.co.jp/buy_news_detail_581.html"
          src="/assets/png/pharmarket-sales-promotion/pharmarket-sales-promotion_60off.png"
          alt="ファイザー製品対薬価比60%オフ ファルマーケット"
        />
      );
    default:
      return null;
  }
};

export function TopContainer() {
  const userMonitoringRepository = repositoryContainerGetter.userMonitoring();
  const currentPharmacy = currentPharmacySelectors.useValue();
  const pharmacyId = currentPharmacy?.id;
  const musubiCode = currentPharmacy?.musubiCode;
  const fromDate = useReceivingStartDate();

  const { useFlags } = repositoryContainerGetter.featureFlag();
  const flags = useFlags();

  const managementNavRoutes = useMemo(
    () => [
      {
        href: '/settings/adopted-medicine',
        label: flags?.tmpPreMadeEnabled ? '採用薬/予製管理' : '採用薬管理',
        isEnabled: true,
      },
      {
        href: '/settings/storage',
        label: '置き場所管理',
        isEnabled: true,
      },
      {
        href: '/settings/counterparty',
        label: '取引先管理',
        isEnabled: true,
      },
      {
        href: '/settings/report/stock',
        label: 'レポート',
        isEnabled: true,
      },
      {
        href: '/settings/trade-slips',
        label: '伝票管理',
        isEnabled: true,
      },
    ],
    [flags],
  );
  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={8}>
      <GridItem colSpan={1}>
        <Stack spacing={5}>
          <Stack spacing={0} borderRadius="md" bg="white" divider={<Divider />}>
            <Flex px={4} py={2} alignItems="center">
              <SettingsIcon color="gray.500" mr={1} />
              <Text fontSize="md" fontWeight="bold">
                管理する
              </Text>
            </Flex>
            {managementNavRoutes.map((route) => {
              if (!route.isEnabled) return null;

              return (
                <Link key={route.href} href={route.href}>
                  <Text px={4} py={2}>
                    {route.label}
                  </Text>
                </Link>
              );
            })}
          </Stack>
          {renderPharmarketPromotionInfobar(flags?.tmpPharmarketInfobar)}
        </Stack>
      </GridItem>
      <GridItem colSpan={3}>
        <Stack spacing="64px">
          <Stack spacing={4}>
            <Flex alignItems="center">
              <FeatureIcon color="gray.500" mr={2} />
              <Typography variant="h2">機能</Typography>
            </Flex>
            <SimpleGrid columns={2} spacing={6}>
              <BigButton label="医薬品を発注する" icon={OrderIcon} href="/order" />
              <BigButton
                label="患者情報/来局予定を確認する"
                icon={PatientInfoIcon}
                href="/patient"
              />
              <BigButton label="在庫を確認する" icon={StockIcon} href="/stock" />
              <BigButton
                label="在庫を計上する"
                icon={ReceivingIcon}
                href={`/receiving/?fromDate=${fromDate}`}
                onClick={() => {
                  userMonitoringRepository.sendEvent({
                    key: 'トップ画面で在庫を計上するをClick',
                    contexts: { pharmacyId, musubiCode },
                  });
                }}
              />
              <BigButton label="棚卸を実施する" icon={StocktakeIcon} href="/stocktake" />
            </SimpleGrid>
          </Stack>
          <NotificationSection />
        </Stack>
      </GridItem>
    </Grid>
  );
}
