import { graphql } from '@/stockSale/gql';

export const queryDoc = graphql(`
  query PharmarketPromotion_PharmarketPromotionBannerInfo {
    pharmarketPromotionBannerInfo {
      immovablyTotalMedicinePriceAmount
      immovablyTotalEstimatePriceAmount
    }
  }
`);
