import { convertToJst, isNullOrUndefined } from '@kkhs/hakari-utils';
import { subDays } from 'date-fns';
import { currentPharmacySelectors, isBeforeOperationStartDate } from '@/entities/currentPharmacy';
import { AWSDate, brandAWSDate, dateToAWSDate } from '@/shared/utils';

// 計上される伝票のほとんどが7日前までのため、7日間をオフセットとして設定
const DATE_OFFSET = 7;

/**
 * 利用開始日と現在日付の7日前を比較して、最新の日付を返す
 * @returns
 */
export function useReceivingStartDate(): AWSDate {
  const fromDate = getFromDate();
  const currentPharmacyState = currentPharmacySelectors.useValue();
  const operationStartDateString = currentPharmacyState?.operationStartDate;

  if (isNullOrUndefined(operationStartDateString)) {
    return dateToAWSDate(fromDate);
  }
  const operationStartDate = brandAWSDate(operationStartDateString);
  const targetDate = dateToAWSDate(fromDate);

  return isBeforeOperationStartDate({
    operationStartDate,
    targetDate,
  })
    ? operationStartDate
    : targetDate;
}

function getFromDate() {
  const convertedToDate = convertToJst(new Date());
  const fromDate = subDays(convertedToDate, DATE_OFFSET);
  return fromDate;
}
