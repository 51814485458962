import { TopContainer } from './TopContainer';
import { Layout, TopLocalNav } from '../ui';

export function TopPage() {
  return (
    <Layout localNav={TopLocalNav} title="TOP">
      <TopContainer />
    </Layout>
  );
}
