import { Box, Flex, Stack } from '@chakra-ui/react';
import { NotebookIcon, Typography } from '@kkhs/hakari-ui';
import { NotificationList } from './NotificationList';

export function NotificationSection() {
  return (
    <Stack spacing={4}>
      <Flex alignItems="center">
        <NotebookIcon color="gray.500" mr={2} />
        <Typography variant="h2">お知らせ</Typography>
      </Flex>
      <Box bg="white" p={6} borderRadius="md">
        <NotificationList />
      </Box>
    </Stack>
  );
}
