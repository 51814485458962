import { Typography } from '@kkhs/hakari-ui';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';

export function TopLocalNav() {
  const currentPharmacy = currentPharmacySelectors.useValue();
  return (
    <Typography as="h2" variant="h2" color="gray.700" ml={-8}>
      {currentPharmacy?.name}
    </Typography>
  );
}
