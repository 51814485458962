import { Center, Stack, Spinner, HStack, Tag } from '@chakra-ui/react';
import { Link, NumberText, Typography } from '@kkhs/hakari-ui';
import { repositoryContainerGetter } from '@/di/repository';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';
import { useReceivingStartDate } from '@/entities/receiving/hooks/useReceivingStartDate';
import { useNotificationBlocks } from './hooks';
import { AI_LEARNING_MAX_COUNT, TRANSFER_REQUEST_MAX_COUNT } from '../constants';
import { NotificationBlock, NotificationType } from '../types';

const tagStyles = {
  w: '52px',
  minHeight: '22px',
  justifyContent: 'center',
  fontWeight: 'bold',
  borderRadius: 'base',
};

function renderNotificationTag(type: NotificationType) {
  switch (type) {
    case 'error':
      return (
        <Tag w="52px" sx={tagStyles} bg="red.100" color="red.500">
          ERROR
        </Tag>
      );
    case 'todo':
      return (
        <Tag sx={tagStyles} bg="primary.100" color="primary.600">
          TODO
        </Tag>
      );
    case 'info':
      return (
        <Tag sx={tagStyles} bg="slate.25" color="slate.500">
          INFO
        </Tag>
      );
    default:
      return null;
  }
}

export function NotificationList() {
  const { isLoading, notificationBlocks } = useNotificationBlocks();
  const userMonitoringRepository = repositoryContainerGetter.userMonitoring();
  const currentPharmacy = currentPharmacySelectors.useValue();
  const pharmacyId = currentPharmacy?.id;
  const musubiCode = currentPharmacy?.musubiCode;
  const fromDate = useReceivingStartDate();

  function renderNotificationBlock(block: NotificationBlock) {
    switch (block.itemType) {
      case 'receiving':
        return (
          <Typography variant="body">
            在庫計上待ち(
            {!!block.count && (
              <>
                卸：
                <Typography textDecoration="underline" variant="body2" as="span">
                  <Link
                    href={`/receiving/?fromDate=${fromDate}`}
                    onClick={() => {
                      userMonitoringRepository.sendEvent({
                        key: 'お知らせで卸リンクをClick',
                        contexts: { pharmacyId, musubiCode },
                      });
                    }}
                  >
                    <NumberText size="md" fontWeight="bold" inline>
                      {block.count}
                    </NumberText>
                    件{block.count >= 100 && '以上'}
                  </Link>
                </Typography>
              </>
            )}
            {!!block.count && !!block.unstoredCount && ' / '}
            {!!block.unstoredCount && (
              <>
                他店舗からの入庫：
                <Typography textDecoration="underline" variant="body2" as="span">
                  <Link
                    href="/stock/transfer-requests/trading/?tab=0"
                    onClick={() => {
                      userMonitoringRepository.sendEvent({
                        key: 'お知らせで他店舗からの入庫リンクをClick',
                        contexts: { pharmacyId, musubiCode },
                      });
                    }}
                  >
                    <NumberText size="md" fontWeight="bold" inline>
                      {block.unstoredCount >= TRANSFER_REQUEST_MAX_COUNT
                        ? TRANSFER_REQUEST_MAX_COUNT
                        : block.unstoredCount}
                    </NumberText>
                    件{block.unstoredCount >= TRANSFER_REQUEST_MAX_COUNT && '以上'}
                  </Link>
                </Typography>
              </>
            )}
            {(!!block.count || !!block.unstoredCount) && !!block.unshippedCount && ' / '}
            {!!block.unshippedCount && (
              <>
                他店舗への出庫：
                <Typography textDecoration="underline" variant="body2" as="span">
                  <Link
                    href="stock/transfer-requests/trading/?tab=1"
                    onClick={() => {
                      userMonitoringRepository.sendEvent({
                        key: 'お知らせで他店舗への出庫リンクをClick',
                        contexts: { pharmacyId, musubiCode },
                      });
                    }}
                  >
                    <NumberText size="md" fontWeight="bold" inline>
                      {block.unshippedCount >= TRANSFER_REQUEST_MAX_COUNT
                        ? TRANSFER_REQUEST_MAX_COUNT
                        : block.unshippedCount}
                    </NumberText>
                    件{block.unshippedCount >= TRANSFER_REQUEST_MAX_COUNT && '以上'}
                  </Link>
                </Typography>
              </>
            )}
            {(!!block.count || !!block.unstoredCount || !!block.unshippedCount) &&
              !!block.beforeShippingCount &&
              ' / '}
            {!!block.beforeShippingCount && (
              <>
                ファルマーケットへの出庫：
                <Typography textDecoration="underline" variant="body2" as="span">
                  <Link
                    href="/stock/sale/?tab=1"
                    onClick={() => {
                      userMonitoringRepository.sendEvent({
                        key: 'お知らせでファルマーケットへの出庫リンクをClick',
                        contexts: { pharmacyId, musubiCode },
                      });
                    }}
                  >
                    <NumberText size="md" fontWeight="bold" inline>
                      {block.beforeShippingCount}
                    </NumberText>
                    件
                  </Link>
                </Typography>
              </>
            )}
            )
          </Typography>
        );
      case 'transferRequest': {
        return (
          <Typography variant="body">
            融通(
            {!!block.othersTakeCount && (
              <>
                他店舗の小分け依頼：
                <Typography textDecoration="underline" variant="body" as="span" fontWeight="bold">
                  <Link
                    href="/stock/transfer-requests/?tab=1"
                    onClick={() => {
                      userMonitoringRepository.sendEvent({
                        key: 'お知らせで他店舗の小分け依頼リンクをClick',
                        contexts: { pharmacyId, musubiCode },
                      });
                    }}
                  >
                    あり
                  </Link>
                </Typography>
              </>
            )}
            {!!block.othersTakeCount && !!block.othersGiveCount && ' / '}
            {!!block.othersGiveCount && (
              <>
                他店舗の引き取り依頼：
                <Typography textDecoration="underline" variant="body" as="span" fontWeight="bold">
                  <Link
                    href="/stock/transfer-requests/?tab=3"
                    onClick={() => {
                      userMonitoringRepository.sendEvent({
                        key: 'お知らせで他店舗の引き取り依頼リンクをClick',
                        contexts: { pharmacyId, musubiCode },
                      });
                    }}
                  >
                    あり
                  </Link>
                </Typography>
              </>
            )}
            {(!!block.othersTakeCount || !!block.othersGiveCount) && !!block.ownGiveCount && ' / '}
            {!!block.ownGiveCount && (
              <>
                引取先選択：
                <Typography textDecoration="underline" variant="body" as="span" fontWeight="bold">
                  <Link
                    href="/stock/transfer-requests/?tab=2"
                    onClick={() => {
                      userMonitoringRepository.sendEvent({
                        key: 'お知らせで引取先選択リンクをClick',
                        contexts: { pharmacyId, musubiCode },
                      });
                    }}
                  >
                    あり
                  </Link>
                </Typography>
              </>
            )}
            {(!!block.othersTakeCount || !!block.othersGiveCount || !!block.ownGiveCount) &&
              !!block.beConfirmedCount &&
              ' / '}
            {!!block.beConfirmedCount && (
              <>
                売却査定承認：
                <Typography textDecoration="underline" variant="body" as="span" fontWeight="bold">
                  <Link
                    href="/stock/sale/?tab=1"
                    onClick={() => {
                      userMonitoringRepository.sendEvent({
                        key: 'お知らせで売却査定承認リンクをClick',
                        contexts: { pharmacyId, musubiCode },
                      });
                    }}
                  >
                    あり
                  </Link>
                </Typography>
              </>
            )}
            )
          </Typography>
        );
      }

      case 'orderPlan':
        return (
          <Typography variant="body">
            <>
              発注予約中：
              <Typography textDecoration="underline" variant="body2" as="span">
                <Link
                  href="/order/plans"
                  onClick={() => {
                    userMonitoringRepository.sendEvent({
                      key: 'お知らせで発注予約中リンクをClick',
                      contexts: { pharmacyId, musubiCode },
                    });
                  }}
                >
                  <NumberText size="md" fontWeight="bold" inline>
                    {block.totalCount}
                  </NumberText>
                  件{block.totalCount >= 100 && '以上'}
                </Link>
              </Typography>
            </>
            {!!block.tomorrorCount && (
              <>
                （明日発注：
                <Typography textDecoration="underline" variant="body2" as="span">
                  <Link
                    href="/order/plans"
                    onClick={() => {
                      userMonitoringRepository.sendEvent({
                        key: 'お知らせで明日発注リンクをClick',
                        contexts: { pharmacyId, musubiCode },
                      });
                    }}
                  >
                    <NumberText size="md" fontWeight="bold" inline>
                      {block.tomorrorCount}
                    </NumberText>
                    件{block.tomorrorCount >= 100 && '以上'}
                  </Link>
                </Typography>
                ）
              </>
            )}
          </Typography>
        );
      case 'aiLearning': {
        return (
          <Typography variant="body">
            AIに学習させる(
            {!!block.inputRequiredCount && (
              <>
                入力必須：
                <Typography textDecoration="underline" variant="body2" as="span">
                  <Link
                    href={`/patient/ai-learning/?inputRequired=true&fromDate=${block.fromDate}&toDate=${block.toDate}`}
                    onClick={() => {
                      userMonitoringRepository.sendEvent({
                        key: 'お知らせで入力必須リンクをClick',
                        contexts: { pharmacyId, musubiCode },
                      });
                    }}
                  >
                    <NumberText size="md" fontWeight="bold" inline>
                      {block.inputRequiredCount >= AI_LEARNING_MAX_COUNT
                        ? AI_LEARNING_MAX_COUNT
                        : block.inputRequiredCount}
                    </NumberText>
                    件{block.inputRequiredCount >= AI_LEARNING_MAX_COUNT && '以上'}
                  </Link>
                </Typography>
              </>
            )}
            {!!block.inputRequiredCount && !!block.checkNeededCount && '・'}
            {!!block.checkNeededCount && (
              <>
                要確認：
                <Typography textDecoration="underline" variant="body2" as="span">
                  <Link
                    href={`/patient/ai-learning/?checkNeeded=true&fromDate=${block.fromDate}&toDate=${block.toDate}`}
                    onClick={() => {
                      userMonitoringRepository.sendEvent({
                        key: 'お知らせで要確認リンクをClick',
                        contexts: { pharmacyId, musubiCode },
                      });
                    }}
                  >
                    <NumberText size="md" fontWeight="bold" inline>
                      {block.checkNeededCount >= AI_LEARNING_MAX_COUNT
                        ? AI_LEARNING_MAX_COUNT
                        : block.checkNeededCount}
                    </NumberText>
                    件{block.checkNeededCount >= AI_LEARNING_MAX_COUNT && '以上'}
                  </Link>
                </Typography>
              </>
            )}
            )
          </Typography>
        );
      }

      default:
        return null;
    }
  }

  if (isLoading) {
    return (
      <Center bg="white" p={6}>
        <Spinner size="md" thickness="3px" color="primary.500" emptyColor="gray.200" />
      </Center>
    );
  }
  if (notificationBlocks === undefined || notificationBlocks.length === 0) {
    return (
      <Center data-testid="notification-list" bg="white" p={6}>
        <Typography variant="body">お知らせはありません</Typography>
      </Center>
    );
  }
  return (
    <Stack data-testid="notification-list" spacing={2} alignItems="start">
      {notificationBlocks.map((block) => (
        <HStack key={block.key}>
          {renderNotificationTag(block.type)}
          {renderNotificationBlock(block)}
        </HStack>
      ))}
    </Stack>
  );
}
